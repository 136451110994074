.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-BG {
  background-image: url("../src/img/main-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.Random{
  background-image: url("../src/img/box-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 400px;
}


.modalClass {  
  text-align: center;  
  margin-top: 100px;  
}  
