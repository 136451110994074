.login {
    text-align: center;
    display: flex;
    min-height: 90vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}



input {
    margin: 10px;
    padding: 0px;
    outline: none;
    border-radius: 5px;
}
       

.field {
    padding-left: 30px;
    width: 80%;
    height: 56px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(0, 0, 0,0.5);
    transition: 0.3s all;
    border: 2px solid #ec1b50;
    border-radius: 12px;
    color: #ffffff;
    /* justify-content: flex-end; */
    
}
  
.field::placeholder {
    color: #ffffff;
    opacity: 1;
}
  
.buttonIn {
    width: 300px;
    position: relative;
}

button {
    position: absolute;
    top: 0;
    border-radius: 8px;
    right: 20px;
    z-index: 5;
    border: none;
    top: 15px;
    height: 50px;
    cursor: pointer;
    color: white;
    background-color: #f51212;
    transform: translateX(2px);
  }
  
