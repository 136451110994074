.draw {
    text-align: center;
    display: flex;
    min-height: 90vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

}

.drawBox {
    background-image: url("../../img/Boxbg_Draw.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 40px 25px 25px 25px;

}


.GridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

}

.item {
    /* background: rgba(55, 143, 63, 0.603); */
    /* background-color: #cccccc; */
}

.itemActive {
    display: block;
    /* background: rgba(143, 55, 55, 0.603); */
    filter: saturate(800%);

}

.item6 {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
}



body {
    margin: 0px;
}

.jqbox_overlay {
    position: fixed;
    width: 100%;
    opacity: 0.5;
    top: 0px;
    margin: 0px;
    min-height: 200px;
    height: 100%;
    background-color: #000;
}

.jqbox_innerhtml {
    position: fixed;
    left: 50%;
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 5% auto;
    width: 500px;
    height: 200px;
    padding: 10px;
    background-color: #FFF;
    border: 5px solid #CCC;
    z-index: 500;
    box-shadow: #000 0 2px 18px;
}