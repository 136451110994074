

.modal {
  background-image:  url("../../../img/box-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(
    1,
    0,
    0,
    0.7
  );
  position: fixed;
  width: 300px;
  height: 200px;
  margin: 50% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}


.modal_overlay {
    position: fixed;
    width: 100%;
    opacity: 0.5;
    top: 0px;
    margin: 0px;
    min-height: 200px;
    height: 100%;
    background-color: #000;
}

.modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}
@supports (offset-rotation: 0deg) {
  offset-rotation: 0deg;
  offset-path: path("M 250,100 S -300,500 -700,-200");
  .modal.off {
    offset-distance: 100%;
  }
}
@media (prefers-reduced-motion) {
  .modal {
    offset-path: none;
  }
}
.modal h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}
.modal .content {
  padding: 1rem;
}

#centered-toggle-button {
  position: absolute;
}